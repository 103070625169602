import { MouseEventHandler } from "react";

export const Arrow = ({ fill = "#2E3767", rotate = false, onClick = () => { return; } }: { fill?: string, rotate?: boolean, onClick?: MouseEventHandler<SVGSVGElement> }) => {
    return (
        <svg onClick={onClick} width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg" style={rotate ? { transform: "rotateX(180deg)" } : {}}>
            <path d="M5.33853 5.99341C5.21801 5.99341 5.10584 5.97424 5.002 5.93591C4.89815 5.89758 4.79944 5.83179 4.70585 5.73853L0.211628 1.26007C0.0731616 1.12211 0.00232823 0.948687 -0.000871766 0.739805C-0.00408843 0.530924 0.0667449 0.354303 0.211628 0.209945C0.356495 0.0655863 0.532128 -0.0065918 0.738528 -0.0065918C0.944928 -0.0065918 1.12056 0.0655863 1.26543 0.209945L5.33853 4.26875L9.41163 0.209945C9.55008 0.0719805 9.72411 0.00139564 9.93373 -0.00180974C10.1433 -0.00499852 10.3206 0.0655863 10.4654 0.209945C10.6103 0.354303 10.6828 0.529329 10.6828 0.735022C10.6828 0.940698 10.6103 1.11571 10.4654 1.26007L5.9712 5.73853C5.87762 5.83179 5.7789 5.89758 5.67505 5.93591C5.57122 5.97424 5.45904 5.99341 5.33853 5.99341Z"
                fill={fill} />
        </svg>
    );
};
