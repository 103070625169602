import React from 'react';
import { t } from 'i18next'; // Assuming you're using i18next for translation
import styles from './styles.module.css';

// Define the type for Footer props with an optional color
interface FooterProps {
    backgroundColor?: string; // Optional prop for background color
    color?: string; // Optional prop for text color
}

const Footer: React.FC<FooterProps> = ({ backgroundColor = "transparent", color = '#403C3A' }) => {
    return (
        <footer className={styles.footer} style={{ backgroundColor }}>
            <div className={styles.footerContainer}>
                <p className={`${styles.footerText} opacity-50`} style={{ color: color }}>
                    {t('footer.cookiePreferences')}
                </p>
                <p className={`${styles.footerText} opacity-50`} style={{ color: color }}>
                    {t('footer.allRightsReserved')}
                </p>
            </div>
            <div>
                <p className={styles.footerText} style={{ fontFamily: 'Geist_Bold', color: color }}>
                    {t('footer.feelingGood')}
                </p>
            </div>
        </footer>
    );
};

export default Footer;
