import { MouseEventHandler } from "react";

export const ArrowCarousel = ({ fill = "#FBFBF9", rotate = false, onClick = () => { return; } }: { fill?: string; rotate?: boolean, onClick?: MouseEventHandler<SVGSVGElement> }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} style={rotate ? { transform: 'rotateX(180)' } : {}}>
            <path d="M0.5 20C0.5 9.23045 9.23045 0.5 20 0.5C30.7696 0.5 39.5 9.23045 39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C9.23045 39.5 0.5 30.7696 0.5 20Z" fill="#FBFBF9"/>
            <path d="M0.5 20C0.5 9.23045 9.23045 0.5 20 0.5C30.7696 0.5 39.5 9.23045 39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C9.23045 39.5 0.5 30.7696 0.5 20Z" stroke="#27A577"/>
            <mask id="mask0_2258_27117"  maskUnits="userSpaceOnUse" x="10" y="10" width="20" height="20">
                <rect x="10" y="10" width="20" height="20" fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_2258_27117)">
                <path d="M16.875 20.75L21.0625 24.9375L20 26L14 20L20 14L21.0625 15.0625L16.875 19.25H26V20.75H16.875Z" fill="#191717"/>
            </g>
        </svg>
    );
};
