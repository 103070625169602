export const HandsOk = ({ width = "100%" }: { fill?: string, width?: string|number, height?:string }) => {
    return (
        <svg width={width} height="auto" viewBox="0 0 943 924" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1954_106590)">
                <path d="M784.875 782.785C582.554 815.016 376.359 606.359 358.862 502.002L358.955 501.983L358.294 497.962C353.691 500.052 348.834 501.704 344.015 503.196C327.96 508.175 309.382 511.552 295.339 502.385C281.808 493.559 277.183 476.217 273.742 460.444L281.497 460.304C275.519 458.68 269.595 456.508 264.503 453.038C245.674 440.28 242.674 414.257 241.435 391.519L246.019 391.661C243.798 390.227 241.744 388.585 240.064 386.562C236.96 382.776 235.423 377.959 233.951 373.224C231.245 364.474 228.529 355.438 229.084 346.228C229.648 337.063 234.163 327.541 242.527 323.619L254.741 325.025C249.312 321.506 244.366 317.323 240.125 312.317C234.195 305.408 229.951 297.109 227.714 288.313C226.178 282.298 225.608 275.851 227.645 269.984C231.755 258.196 244.936 252.258 257.214 249.709L301.352 238.831C331.6 233.698 333.124 213.758 334.069 189.001C337.097 110.907 360.087 60.5772 383.657 56.7782C384.212 56.6898 384.961 56.5204 385.857 56.318C392.597 54.7943 407.629 51.3961 410.651 66.5192C427.69 153.008 585.764 480.616 732.906 451.22C916.66 414.509 953.275 219.505 953.275 219.505L1144.16 489.841C1144.16 489.841 1087.69 734.595 784.875 782.785Z" fill="#FFE5CC"/>
                <path d="M241.435 391.519L241.389 391.518L241.435 391.508L241.435 391.519Z" fill="#FFE5CC"/>
                <path d="M273.742 460.444C273.738 460.426 273.735 460.408 273.731 460.39L273.694 460.445L273.742 460.444Z" fill="#FFE5CC"/>
                <g clip-path="url(#clip1_1954_106590)">
                    <path d="M953.416 220.136C953.416 220.136 916.81 415.14 733.05 451.852C585.911 481.249 427.833 153.627 410.793 67.13C407.408 49.9862 388.515 56.6439 383.779 57.3899C360.189 61.1818 337.213 111.543 334.186 189.595C333.241 214.331 331.705 234.301 301.453 239.423L257.291 250.288C245.023 252.82 231.837 258.778 227.707 270.575C225.672 276.428 226.241 282.881 227.764 288.904C230.004 297.707 234.261 305.987 240.166 312.896C255.357 330.805 279.499 338.195 302.633 342.343C327.261 346.754 352.366 348.546 377.365 347.596C389.333 347.167 401.332 346.091 412.807 342.798C420.008 340.758 427.633 337.233 430.623 330.348C431.994 327.191 432.242 323.618 432.034 320.175C431.482 309.794 432.538 303.256 420.527 291.84C408.554 280.416 341.987 267.326 320.123 265.927" stroke="#191717" stroke-width="8.38304" stroke-miterlimit="10"/>
                    <path d="M242.636 324.255C234.287 328.166 229.741 337.684 229.177 346.887C228.613 356.09 231.337 365.116 234.054 373.904C235.516 378.617 237.04 383.439 240.158 387.221C243.061 390.725 247.096 393.083 251.115 395.163C293.321 417.205 345.443 419.126 389.175 400.297C395.036 397.765 400.821 394.847 405.627 390.643C418.205 379.641 422.145 361.474 420.977 344.809" stroke="#191717" stroke-width="8.38304" stroke-miterlimit="10"/>
                    <path d="M241.582 392.143C242.84 414.877 245.838 440.908 264.65 453.689C270.209 457.463 276.701 459.69 283.247 461.386C320.026 471.097 360.405 466.354 393.94 448.401C397.051 446.739 400.132 444.922 402.736 442.52C414.698 431.44 412.292 411.578 404.655 397.206" stroke="#191717" stroke-width="8.38304" stroke-miterlimit="10"/>
                    <path d="M273.852 461.06C277.283 476.833 281.916 494.208 295.453 503.037C309.498 512.204 328.082 508.812 344.148 503.84C354.591 500.592 365.251 496.62 373.1 489.005C380.949 481.391 385.31 469.146 380.869 459.142" stroke="#191717" stroke-width="8.38304" stroke-miterlimit="10"/>
                    <path d="M359.004 502.634C376.487 606.982 582.676 815.629 785.034 783.411C1087.87 735.202 1144.3 490.467 1144.3 490.467" stroke="#191717" stroke-width="8.38304" stroke-miterlimit="10"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1954_106590">
                    <rect width="1045.73" height="1045.73" fill="white" transform="translate(0 -35.1289) rotate(-11.298)"/>
                </clipPath>
                <clipPath id="clip1_1954_106590">
                    <rect width="881.981" height="802.893" fill="white" transform="translate(171.085 93.7959) rotate(-11.298)"/>
                </clipPath>
            </defs>
        </svg>
    );
};
