export const WellbeingHand = ({ isShort = false }) => {
    if (!isShort) {
        return (
            <svg width="515" height="481" viewBox="0 0 515 481" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2418_33987)">
                <g clip-path="url(#clip1_2418_33987)">
                <path d="M344.329 303.737C337.684 317.438 321.068 349.792 317.451 395.237C313.834 440.681 309.501 560.411 311.668 581.324L148.38 587.097C148.38 587.097 146.213 510.641 148.38 454.382C150.547 398.124 173.185 269.011 217.984 212.028L304.366 206.911L344.329 259.806V303.737Z" fill="#FFE5CC" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
                <path d="M272.898 366.387C283.979 366.864 287.348 366.387 292.645 372.637" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
                <path d="M369.236 129.33C379.83 125.004 384.649 134.141 387.541 140.868C390.432 147.595 391.396 156.74 384.171 162.99C376.947 169.24 358.641 181.74 358.641 181.74C358.641 181.74 334.075 187.99 330.22 173.089C326.364 158.187 328.292 150.967 336.003 146.641C343.714 142.315 369.236 129.33 369.236 129.33Z" fill="#FFE5CC" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
                <path d="M381.272 175.49C391.389 174.528 401.096 172.441 406.802 187.028C410.419 196.266 404.934 208.468 394.34 211.346C383.746 214.224 355.742 218.831 349.968 217.588C344.193 216.345 333.112 210.58 333.59 200.481C334.068 190.382 337.591 187.496 350.548 182.923C363.505 178.351 381.281 175.473 381.281 175.473L381.272 175.49Z" fill="#FFE5CC" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
                <path d="M350.444 223.097C344.653 227.61 338.886 232.106 341.292 240.349C343.697 248.591 348.756 255.795 358.991 256.28C369.227 256.766 389.946 250.992 398.135 246.667C406.324 242.341 410.7 237.053 411.646 227.431C412.593 217.81 403.697 211.73 399.423 212.045C389.708 212.76 356.449 218.423 350.453 223.097H350.444Z" fill="#FFE5CC" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
                <path d="M289.661 10.0777C280.295 10.5971 250.321 13.6029 242.021 14.2416C233.722 14.8802 221.191 15.8424 215.578 20.9769C209.966 26.1115 206.102 29.6281 205.138 40.8509C204.174 52.0737 204.814 69.0611 205.138 76.1115C205.462 83.1619 211.885 169.751 212.849 183.034C213.813 196.318 218.308 244.725 219.596 250.814C220.884 256.902 223.016 271.973 229.491 276.137C235.965 280.301 259.576 284.439 268.404 283.818C277.233 283.196 295.376 282.872 304.69 282.234C314.005 281.595 344.551 277.832 354.326 273.463C364.101 269.095 375.599 260.759 376.273 246.335C376.947 231.91 375.343 208.826 374.704 197.612C374.064 186.398 366.353 103.683 364.11 86.057C361.866 68.431 362.259 41.8216 359.009 32.8468C355.759 23.872 350.3 19.6997 342.273 15.2123C334.246 10.7249 299.982 9.51574 289.661 10.0777Z" fill="#0C5138"/>
                <path d="M262.62 21.9393C254.909 23.2251 237.892 25.141 234.036 31.2377C230.18 37.3344 229.217 45.9857 229.541 50.4731C229.865 54.9605 232.432 105.293 233.712 119.07C234.991 132.847 240.041 194.24 242.011 210.989C243.982 227.738 243.342 254.347 247.837 262.037C252.333 269.726 256.188 271.335 262.611 272.612C269.034 273.889 285.087 273.574 300.185 272.612C315.283 271.65 344.183 268.636 349.957 265.655C355.732 262.675 365.695 252.415 366.011 246.326C366.326 240.238 366.975 208.818 366.011 197.604C365.047 186.389 350.921 53.3511 350.597 46.3007C350.273 39.2503 348.192 30.5906 340.719 26.1032C333.247 21.6158 310.131 18.7292 300.817 19.3678C291.502 20.0064 262.603 21.9308 262.603 21.9308L262.62 21.9393Z" fill="#FBFBF9"/>
                <path d="M247.744 131.74C242.925 133.664 241.645 138.152 242.285 141.353C242.925 144.555 242.609 149.051 247.744 149.689C252.879 150.328 260.266 149.366 259.95 145.202C259.635 141.038 259.311 133.017 257.059 131.74C254.807 130.463 247.744 131.74 247.744 131.74Z" fill="#FFCC00"/>
                <path d="M287.324 167.077C286.283 169.836 287.674 172.093 289.26 173.063C290.847 174.034 292.612 175.89 294.856 173.983C297.099 172.076 299.539 168.619 297.662 167.154C295.786 165.689 292.297 162.743 290.898 163.186C289.499 163.629 287.315 167.086 287.315 167.086L287.324 167.077Z" fill="#FFE5CC"/>
                <path d="M323.755 116.192C312.009 122.62 302.908 127.917 301.773 140.025C300.639 152.133 300.639 168.022 308.972 171.803C317.315 175.584 337.778 180.786 342.137 175.158C346.496 169.53 357.866 156.825 354.838 146.914C351.81 137.002 348.014 126.784 342.137 122.62C336.26 118.457 323.755 116.192 323.755 116.192Z" fill="#97ADFF"/>
                <path d="M293.789 122.39C285.396 118.806 278.759 116.149 272.822 120.389C266.877 124.621 259.78 130.965 262.007 136.84C264.233 142.716 271.517 155.514 276.072 155.548C280.627 155.582 291.648 156.467 294.65 150.924C297.653 145.381 300.442 139.318 299.547 134.575C298.651 129.832 293.789 122.382 293.789 122.382V122.39Z" fill="#20CF27"/>
                <path d="M247.198 169.07C249.126 173.233 256.035 176.725 256.035 176.725C256.035 176.725 262.288 179.126 267.431 176.18C272.575 173.233 274.519 164.259 273.863 161.696C273.206 159.133 268.574 153.811 264.949 152.789C261.324 151.767 255.549 149.971 252.657 152.789C249.766 155.607 245.91 160.095 245.91 162.981C245.91 165.868 247.198 169.07 247.198 169.07Z" fill="#A48FFA"/>
                <path d="M256.164 195.611L258.134 211.849C258.134 211.849 287.742 209.951 294.293 209.312C300.844 208.673 351.051 205.225 351.051 205.225L349.336 188.799L283.426 193.287L256.173 195.611H256.164Z" fill="#E1DFC7"/>
                <path d="M239.514 70.6876L241.484 86.9342L296.502 83.4686L294.779 67.0347L239.514 70.6876Z" fill="#C5C4B0"/>
                <path d="M242.021 90.5874L243.982 106.825L323.95 100.959L322.236 84.5332L242.021 90.5874Z" fill="#C5C4B0"/>
                <path d="M256.164 195.611L258.134 211.849C258.134 211.849 287.742 209.95 294.293 209.312C300.844 208.673 313.69 206.808 313.69 206.808L311.975 190.383L283.426 193.286L256.173 195.611H256.164Z" fill="#27A577"/>
                <path d="M338.91 269.12C328.802 272.611 328.031 271.367 320.32 276.663C312.609 281.959 310.844 290.449 312.771 296.699C314.699 302.949 320.38 310.782 331.128 310.782C341.876 310.782 353.877 307.274 363.507 304.388C373.138 301.501 381.804 296.213 383.971 286.6C386.137 276.986 382.418 268.948 379.399 262.817C375.81 255.532 363.507 258.057 363.507 258.057" fill="#FFE5CC"/>
                <path d="M338.91 269.12C328.802 272.611 328.031 271.367 320.32 276.663C312.609 281.959 310.844 290.449 312.771 296.699C314.699 302.949 320.38 310.782 331.128 310.782C341.876 310.782 353.877 307.274 363.507 304.388C373.138 301.501 381.804 296.213 383.971 286.6C386.137 276.986 382.418 268.948 379.399 262.817C375.81 255.532 363.507 258.057 363.507 258.057C359.122 260.317 348.062 265.694 338.91 269.12Z" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
                <path d="M194.381 308.69C204.975 292.342 223.766 263.97 232.919 253.871C242.072 243.772 269.094 208.673 280.149 219.734C291.204 230.795 291.681 233.682 286.384 246.182C281.087 258.682 275.304 269.743 277.232 283.205C279.16 296.667 286.862 313.74 286.862 313.74" fill="#FFE5CC"/>
                <path d="M194.381 308.69C204.975 292.342 223.766 263.97 232.919 253.871C242.072 243.772 269.094 208.673 280.149 219.734C291.204 230.795 291.681 233.682 286.384 246.182C281.087 258.682 275.304 269.743 277.232 283.205C279.16 296.667 286.862 313.74 286.862 313.74" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
                </g>
                </g>
                <defs>
                <clipPath id="clip0_2418_33987">
                <rect width="572" height="571" fill="white" transform="translate(0 0.75)"/>
                </clipPath>
                <clipPath id="clip1_2418_33987">
                <rect width="270.27" height="580.229" fill="white" transform="translate(144.43 9.95044)"/>
                </clipPath>
                </defs>
            </svg>
        );
    }

    return (
        <svg width="515" height="368" viewBox="0 0 515 368" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2418_34162)">
            <g clip-path="url(#clip1_2418_34162)">
            <path d="M344.329 303.237C337.684 316.937 321.068 349.292 317.451 394.737C313.834 440.181 309.501 559.911 311.668 580.823L148.38 586.597C148.38 586.597 146.213 510.141 148.38 453.882C150.547 397.623 173.185 268.511 217.984 211.528L304.366 206.411L344.329 259.306V303.237Z" fill="#FFE5CC" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
            <path d="M272.898 365.888C283.979 366.364 287.348 365.888 292.645 372.138" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
            <path d="M369.236 128.83C379.83 124.504 384.649 133.641 387.541 140.368C390.432 147.095 391.396 156.24 384.171 162.49C376.947 168.74 358.641 181.24 358.641 181.24C358.641 181.24 334.075 187.49 330.22 172.589C326.364 157.687 328.292 150.467 336.003 146.141C343.714 141.815 369.236 128.83 369.236 128.83Z" fill="#FFE5CC" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
            <path d="M381.272 174.99C391.389 174.028 401.096 171.941 406.802 186.528C410.419 195.766 404.934 207.968 394.34 210.846C383.746 213.724 355.742 218.331 349.968 217.088C344.193 215.845 333.112 210.08 333.59 199.981C334.068 189.882 337.591 186.996 350.548 182.423C363.505 177.851 381.281 174.973 381.281 174.973L381.272 174.99Z" fill="#FFE5CC" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
            <path d="M350.444 222.597C344.653 227.11 338.886 231.606 341.292 239.849C343.697 248.091 348.756 255.295 358.991 255.78C369.227 256.266 389.946 250.492 398.135 246.167C406.324 241.841 410.7 236.553 411.646 226.931C412.593 217.31 403.697 211.23 399.423 211.545C389.708 212.26 356.449 217.923 350.453 222.597H350.444Z" fill="#FFE5CC" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
            <path d="M289.661 9.57785C280.295 10.0973 250.321 13.1031 242.021 13.7417C233.722 14.3803 221.191 15.3425 215.578 20.477C209.966 25.6116 206.102 29.1283 205.138 40.351C204.174 51.5738 204.814 68.5612 205.138 75.6116C205.462 82.662 211.885 169.251 212.849 182.534C213.813 195.818 218.308 244.225 219.596 250.314C220.884 256.402 223.016 271.473 229.491 275.637C235.965 279.801 259.576 283.939 268.404 283.318C277.233 282.696 295.376 282.373 304.69 281.734C314.005 281.095 344.551 277.332 354.326 272.964C364.101 268.595 375.599 260.259 376.273 245.835C376.947 231.41 375.343 208.326 374.704 197.112C374.064 185.898 366.353 103.183 364.11 85.5571C361.866 67.9311 362.259 41.3217 359.009 32.3469C355.759 23.3721 350.3 19.1998 342.273 14.7124C334.246 10.225 299.982 9.01586 289.661 9.57785Z" fill="#0C5138"/>
            <path d="M262.62 21.4392C254.909 22.725 237.892 24.6408 234.036 30.7376C230.18 36.8343 229.217 45.4855 229.541 49.9729C229.865 54.4603 232.432 104.792 233.712 118.57C234.991 132.347 240.041 193.74 242.011 210.489C243.982 227.238 243.342 253.847 247.837 261.536C252.333 269.225 256.188 270.835 262.611 272.112C269.034 273.389 285.087 273.074 300.185 272.112C315.283 271.15 344.183 268.136 349.957 265.155C355.732 262.175 365.695 251.914 366.011 245.826C366.326 239.738 366.975 208.318 366.011 197.103C365.047 185.889 350.921 52.851 350.597 45.8006C350.273 38.7502 348.192 30.0904 340.719 25.603C333.247 21.1156 310.131 18.2291 300.817 18.8677C291.502 19.5063 262.603 21.4307 262.603 21.4307L262.62 21.4392Z" fill="#FBFBF9"/>
            <path d="M247.744 131.24C242.925 133.164 241.645 137.652 242.285 140.853C242.925 144.055 242.609 148.551 247.744 149.189C252.879 149.828 260.266 148.866 259.95 144.702C259.635 140.538 259.311 132.517 257.059 131.24C254.807 129.963 247.744 131.24 247.744 131.24Z" fill="#FFCC00"/>
            <path d="M287.324 166.577C286.283 169.336 287.674 171.593 289.26 172.563C290.847 173.534 292.612 175.39 294.856 173.483C297.099 171.576 299.539 168.119 297.662 166.654C295.786 165.189 292.297 162.243 290.898 162.686C289.499 163.129 287.315 166.586 287.315 166.586L287.324 166.577Z" fill="#FFE5CC"/>
            <path d="M323.755 115.692C312.009 122.121 302.908 127.417 301.773 139.525C300.639 151.634 300.639 167.523 308.972 171.303C317.315 175.084 337.778 180.287 342.137 174.658C346.496 169.03 357.866 156.325 354.838 146.414C351.81 136.502 348.014 126.284 342.137 122.121C336.26 117.957 323.755 115.692 323.755 115.692Z" fill="#97ADFF"/>
            <path d="M293.789 121.89C285.396 118.306 278.759 115.649 272.822 119.889C266.877 124.121 259.78 130.465 262.007 136.34C264.233 142.216 271.517 155.014 276.072 155.048C280.627 155.082 291.648 155.967 294.65 150.424C297.653 144.881 300.442 138.818 299.547 134.075C298.651 129.332 293.789 121.882 293.789 121.882V121.89Z" fill="#20CF27"/>
            <path d="M247.198 168.57C249.126 172.734 256.035 176.225 256.035 176.225C256.035 176.225 262.288 178.626 267.431 175.68C272.575 172.734 274.519 163.759 273.863 161.196C273.206 158.633 268.574 153.311 264.949 152.289C261.324 151.267 255.549 149.471 252.657 152.289C249.766 155.108 245.91 159.595 245.91 162.482C245.91 165.368 247.198 168.57 247.198 168.57Z" fill="#A48FFA"/>
            <path d="M256.164 195.111L258.134 211.349C258.134 211.349 287.742 209.451 294.293 208.812C300.844 208.173 351.051 204.725 351.051 204.725L349.336 188.299L283.426 192.787L256.173 195.111H256.164Z" fill="#E1DFC7"/>
            <path d="M239.514 70.1875L241.484 86.4341L296.502 82.9685L294.779 66.5345L239.514 70.1875Z" fill="#C5C4B0"/>
            <path d="M242.021 90.0873L243.982 106.325L323.95 100.459L322.236 84.0331L242.021 90.0873Z" fill="#C5C4B0"/>
            <path d="M256.164 195.111L258.134 211.349C258.134 211.349 287.742 209.45 294.293 208.812C300.844 208.173 313.69 206.308 313.69 206.308L311.975 189.883L283.426 192.786L256.173 195.111H256.164Z" fill="#27A577"/>
            <path d="M338.91 268.619C328.802 272.111 328.031 270.866 320.32 276.163C312.609 281.459 310.844 289.949 312.771 296.199C314.699 302.449 320.38 310.282 331.128 310.282C341.876 310.282 353.877 306.774 363.507 303.888C373.138 301.001 381.804 295.713 383.971 286.1C386.137 276.486 382.418 268.448 379.399 262.317C375.81 255.032 363.507 257.556 363.507 257.556" fill="#FFE5CC"/>
            <path d="M338.91 268.619C328.802 272.111 328.031 270.866 320.32 276.163C312.609 281.459 310.844 289.949 312.771 296.199C314.699 302.449 320.38 310.282 331.128 310.282C341.876 310.282 353.877 306.774 363.507 303.888C373.138 301.001 381.804 295.713 383.971 286.1C386.137 276.486 382.418 268.448 379.399 262.317C375.81 255.032 363.507 257.556 363.507 257.556C359.122 259.817 348.062 265.194 338.91 268.619Z" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
            <path d="M194.381 308.19C204.975 291.842 223.766 263.47 232.919 253.371C242.072 243.272 269.094 208.173 280.149 219.234C291.204 230.295 291.681 233.182 286.384 245.682C281.087 258.182 275.304 269.243 277.232 282.705C279.16 296.167 286.862 313.24 286.862 313.24" fill="#FFE5CC"/>
            <path d="M194.381 308.19C204.975 291.842 223.766 263.47 232.919 253.371C242.072 243.272 269.094 208.173 280.149 219.234C291.204 230.295 291.681 233.182 286.384 245.682C281.087 258.182 275.304 269.243 277.232 282.705C279.16 296.167 286.862 313.24 286.862 313.24" stroke="#EE8C4B" stroke-width="4.27496" stroke-miterlimit="10"/>
            </g>
            </g>
            <defs>
            <clipPath id="clip0_2418_34162">
            <rect width="572" height="571" fill="white" transform="translate(0 0.25)"/>
            </clipPath>
            <clipPath id="clip1_2418_34162">
            <rect width="270.27" height="580.229" fill="white" transform="translate(144.43 9.45044)"/>
            </clipPath>
            </defs>
        </svg>
    );
};
