import { useTranslation } from 'react-i18next';
import { ENFlag, LanguageArrow, FRFlag, NLFlag } from '../../assets/svg';
import { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { changeLanguage } from 'i18next';

type LanguageSwitcherProps = {
  textColor?: string;
};

const LanguageSwitcher: FC<LanguageSwitcherProps> = ({ textColor = '' }) => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const onLanguageSwitch = (language: string) => {
    i18n.changeLanguage(language);
    changeLanguage(language);
    setSelectedLanguage(language);
    setDropdownOpen(false);
  };

  const getFlagComponent = (language: string) => {
    switch (language) {
      case 'fr':
        return <FRFlag width="24px" />;
      case 'nl':
        return <NLFlag width="24px" />;
      case 'en':
      default:
        return <ENFlag width="24px" />;
    }
  };

  const availableLanguages = [
    { code: 'en', flag: <ENFlag width="24px" />, label: 'en' },
    { code: 'nl', flag: <NLFlag width="24px" />, label: 'nl' },
    { code: 'fr', flag: <FRFlag width="24px" />, label: 'fr' },
  ];

  useEffect(() => {
    const locale = localStorage.getItem('i18nextLng');
    console.log('localStorageLocale', locale);

    if (locale) {
      console.log('localeToBeSet', locale.substring(0, 2));
      changeLanguage(locale.substring(0, 2));
      setSelectedLanguage(locale.substring(0, 2));
    }
  }, []);

  useEffect(() => {
    if (i18n.language.length > 2) {
      changeLanguage(i18n.language.substring(0, 2));
      setSelectedLanguage(i18n.language.substring(0, 2));
    }
  }, [i18n.language]);

  return (
    <div className={styles.container} onClick={() => setDropdownOpen(!dropdownOpen)}>
      <div className={styles.selectedLanguage} style={dropdownOpen ? { backgroundColor: '#fff' } : { backgroundColor: 'transparent' }}>
        {getFlagComponent(selectedLanguage)}
        <span className={styles.text} style={!dropdownOpen && textColor ? { color: textColor } : {}}>{selectedLanguage}</span>
        <LanguageArrow width="24px" fill={!dropdownOpen && textColor ? textColor : '#191717'} />
      </div>
      {dropdownOpen && (
        <div className={styles.dropdown}>
          {availableLanguages
            .filter(language => language.code !== selectedLanguage)
            .map(language => (
              <div
                key={language.code}
                className={styles.languageOption}
                onClick={() => onLanguageSwitch(language.code)}
              >
                {language.flag}
                <span className={styles.text}>{language.label}</span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
