export const NotificationHand = ({ width = "514", height="527" }: { width?: string|number, height?: string|number }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 514 527" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1955_167619)">
                <path d="M209.478 248.155L189.301 121.93C188.251 115.358 192.278 109.043 198.682 107.222L292.513 80.5372C300.264 78.3329 308.17 83.5442 309.195 91.5338L340.452 334.98C341.412 342.454 335.906 349.195 328.386 349.754L239.99 356.32L209.478 248.155Z" fill="#C5C4B0"/>
                <path d="M235.331 364.654C250.028 415.096 393.577 526.518 529.415 471.068L617.024 289.59C438.553 402.634 364.793 337.391 334.967 301.432L340.066 334.674C341.228 342.25 335.689 349.202 328.04 349.767L291.187 352.489C291.187 352.489 241.661 356.702 236.892 357.671C233.766 370.304 235.355 364.666 235.355 364.666" fill="#FFE5CC"/>
                <path d="M175.888 275.598C171.997 278.005 167.486 291.169 167.743 295.738C168 300.308 169.888 304.595 171.746 308.79C172.742 311.03 173.816 311.749 175.58 313.453C177.216 315.014 179.666 315.738 181.771 316.533C203.875 324.978 227.599 329.579 248.078 317.787C250.822 316.202 253.328 315.145 255.455 312.797C261.02 306.651 264.425 300.671 261.928 290.985" fill="#FFE5CC"/>
                <path d="M172.067 317.026C174.011 328.162 177.993 343.168 188.018 348.389C190.98 349.931 194.312 350.657 197.64 351.117C216.35 353.795 233.549 352.964 249.048 342.189C250.487 341.191 252.883 336.459 254.028 335.126C259.285 328.977 254.604 320.079 250.001 313.432" fill="#FFE5CC"/>
                <path d="M186.96 347.848C189.571 355.428 193.308 365.082 200.5 368.658C207.963 372.371 216.933 369.633 224.57 366.26C229.533 364.059 234.561 361.489 237.99 357.285C241.419 353.08 243.02 346.598 238.579 340.574" fill="#FFE5CC"/>
                <path d="M210.481 249.172C223.992 251.507 249.178 258.784 256.42 262.477C262.991 267.422 266.646 269.518 267.523 274.604C267.807 276.295 267.283 284.069 266.771 285.71C265.697 289.276 261.047 289.981 257.613 291.4C252.082 293.682 244.47 290.959 238.554 291.869C228.167 293.468 216.39 291.867 205.892 291.554C192.731 291.181 185.812 284.853 175.866 275.589C172.459 272.428 170.691 270.274 169.088 265.915C167.823 262.471 165.641 255.388 167.12 252.06C174.186 236.164 179.544 229.696 192.107 219.79C192.107 219.79 197.525 187.558 198.674 185.67C201.077 181.715 201.935 202.304 201.935 202.304L210.481 249.172Z" fill="#FFE5CC"/>
                <path d="M622.863 284.551C428.753 408.656 361.168 334.435 337.477 303.797" stroke="#EE8C4B" stroke-width="3.94831" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M174.773 277.663C170.523 279.346 167.997 283.934 167.446 288.478C166.895 293.021 167.991 297.585 169.082 302.008C169.67 304.379 170.281 306.825 171.723 308.798C173.055 310.621 174.989 311.901 176.924 313.058C197.207 325.227 223.02 327.737 245.237 319.708C248.231 318.636 251.177 317.355 253.683 315.417C260.234 310.346 262.735 301.462 262.634 293.153" stroke="#EE8C4B" stroke-width="3.94831" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M172.28 311.261C172.242 322.546 172.969 335.551 181.938 342.445C184.589 344.477 187.757 345.768 190.926 346.812C208.88 352.705 229.049 351.548 246.188 343.666C247.773 342.928 249.376 342.122 250.717 341.012C256.96 335.876 256.359 325.967 252.982 318.616" stroke="#EE8C4B" stroke-width="3.94831" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M186.308 346.359C187.553 354.277 189.347 363.002 195.803 367.778C202.504 372.742 211.834 371.609 219.923 369.626C225.201 368.329 230.593 366.686 234.708 363.141C238.824 359.596 241.347 353.676 239.44 348.575" stroke="#EE8C4B" stroke-width="3.94831" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M235.487 364.836C250.183 415.274 379.66 529.24 538.194 468.778" stroke="#EE8C4B" stroke-width="3.94831" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M199.245 183.728C192.169 198.274 192.131 219.804 192.131 219.804C183.693 225.568 176.313 233.51 170.843 243.54C169.08 246.754 166.454 255.884 167.131 259.477C167.981 264.039 169.911 268.394 172.735 272.092C180.985 282.887 195.584 286.915 208.502 289.498C218.808 291.553 229.288 292.692 239.799 292.856C245.776 292.951 251.817 292.739 257.638 291.408C261.266 290.583 265.151 289.039 266.797 285.719C267.567 284.178 267.753 282.417 267.753 280.716C267.734 275.557 268.436 272.359 262.779 266.381C257.121 260.403 212.702 248.481 201.915 247.217" stroke="#EE8C4B" stroke-width="3.56664" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M220.192 143.931C224.187 142.639 226.372 138.353 225.072 134.359C223.772 130.365 219.479 128.175 215.483 129.468C211.488 130.761 209.303 135.046 210.603 139.04C211.903 143.034 216.196 145.224 220.192 143.931Z" stroke="#191717" stroke-width="4.04043" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M224.499 172.788C228.495 171.495 230.68 167.209 229.379 163.215C228.079 159.221 223.786 157.031 219.791 158.324C215.796 159.617 213.611 163.903 214.911 167.897C216.211 171.891 220.504 174.081 224.499 172.788Z" stroke="#191717" stroke-width="4.04043" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M209.483 248.153L189.302 121.923C188.251 115.35 192.279 109.036 198.683 107.214L292.503 80.5331C300.254 78.3289 308.16 83.54 309.186 91.5295L340.451 334.987C341.411 342.461 335.905 349.202 328.385 349.761L239.997 356.323" stroke="#191717" stroke-width="3.94831"/>
                <path d="M283.364 83.4007L283.7 83.3405C291.107 82.0121 298.129 87.1479 299.102 94.6061L330.621 336.209C331.544 343.281 326.313 349.659 319.192 350.145V350.145" stroke="#191717" stroke-width="3.94831"/>
                <circle cx="341.247" cy="60.222" r="13.161" transform="rotate(24.6835 341.247 60.222)" fill="#C7BCFF"/>
                <circle cx="352.133" cy="113.748" r="7.23856" transform="rotate(24.6835 352.133 113.748)" stroke="#79BAEB" stroke-width="2.6322"/>
                <path d="M368.573 76.4024L363.53 84.2237M382.515 115.4L390.288 118.973M356.137 146.006L361.388 153.489" stroke="#CECDB8" stroke-width="2.6322" stroke-linecap="round"/>
                <path d="M418.377 75.3459C419.794 73.8977 422.248 74.5268 422.793 76.4783L429.113 99.0846C429.659 101.036 427.887 102.846 425.925 102.343L403.187 96.513C401.224 96.0098 400.542 93.5705 401.959 92.1223L418.377 75.3459Z" fill="#FEC1D2"/>
                <path d="M408.098 153.018C409.827 151.144 412.944 152.577 412.647 155.109L412.31 157.985C412.178 159.118 412.789 160.207 413.826 160.683L416.456 161.892C418.773 162.957 418.374 166.364 415.874 166.865L413.035 167.433C411.916 167.657 411.069 168.575 410.937 169.708L410.6 172.583C410.303 175.116 406.939 175.789 405.69 173.566L404.273 171.041C403.714 170.047 402.579 169.525 401.461 169.749L398.622 170.317C396.122 170.817 394.442 167.826 396.171 165.952L398.134 163.823C398.907 162.985 399.052 161.744 398.494 160.75L397.076 158.225C395.828 156.002 398.154 153.48 400.47 154.545L403.101 155.754C404.137 156.23 405.362 155.985 406.135 155.147L408.098 153.018Z" fill="#E1B400"/>
            </g>
            <defs>
                <clipPath id="clip0_1955_167619">
                    <rect width="510.963" height="510.069" fill="white" transform="translate(213.008 -61) rotate(24.6835)"/>
                </clipPath>
            </defs>
        </svg>
    );
};
