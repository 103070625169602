import { t } from "i18next";
import { MouseEventHandler, ReactNode } from "react";

interface ButtonProps {
    id?: string,
    type?: "button" | "submit" | "reset",
    buttonType?: "primary" | "secondary" | "big",
    icon?: ReactNode,
    text: string,
    className?: string,
    style?: any,
    onClick?: MouseEventHandler
}

export const Button = ({
    id = "",
    type = "button",
    buttonType = "primary",
    icon = null,
    text,
    className = "",
    style = {},
    onClick = () => {}
}: ButtonProps) => {
    return (
        <button
            id={id}
            className={`btn btn-${buttonType} ${className}`}
            style={style}
            onClick={onClick}
            type={type}
        >
            {text}
            {icon}
        </button>
    );
};
