import { ChangeEvent, FC, MouseEventHandler, useState } from 'react';
import Navbar from '../../components/Navbar';
import Paper from '@mui/material/Paper';
import { Button } from '../../components/Button';
import { useForm } from 'react-hook-form';
import crypto from 'crypto';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { publicKey } from './publikcKey';
import { useTranslation, Trans } from 'react-i18next';
import styles from './styles.module.css';
import Input from '../../components/Input';
import { t } from 'i18next';
import { ArrowCarousel } from '../../assets/svg';
import { Link } from 'react-router-dom';
import ScannerFace from '../../assets/images/scanner_face.png';
import CustomRadioButton from '../../components/CustomRadioButton';
import CheckIcon from '../../assets/images/check.png';
import Footer from '../../components/Footer';

type User = {
  email: string;
  weight: number;
  height: number;
  locale?: string;
  pharmacy: string;
  pharmacyReferral: string;
  pharmacyIcon: string;
  pharmacyName: string;
  gender: string;
  firstname: string;
  terms: boolean;
  event: string;
};

const Step1 = ({
  error,
  formData,
  handleChange,
  handleNext,
  setFormData,
}: {
  error: number;
  formData: any;
  control: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleNext: MouseEventHandler<HTMLButtonElement>;
  setFormData: any;
}): JSX.Element => (
  <div className={`${styles.content}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <Link to="/intro" style={{ textDecoration: 'none' }}>
          <ArrowCarousel fill="#191717" />
        </Link>
      </div>
      <div className={styles.progressContainer}>
        <p className={styles.progressText}>{t('profile.step1.progressText')}</p>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBar} style={{ width: '25%' }}></div>
        </div>
      </div>
    </div>
    <h1 className={styles.title}>{t('profile.step1.title')}</h1>
    <div className={styles.textContainer}>
      <div className={styles.formContainer}>
        <Input
          required={true}
          id='age'
          type='number'
          label={t('profile.step1.age')}
          name="age"
          value={formData.age}
          onChange={handleChange}
          min={13}
          max={120}
        />
        <CustomRadioButton
          title={t('profile.step1.gender')}
          formKey="gender"
          form={formData}
          setForm={setFormData}
          options={[
            { label: t('profile.step1.man'), value: 'male' },
            { label: t('profile.step1.woman'), value: 'female' },
          ]}
        />
        <CustomRadioButton
          title={t('profile.step1.smoker')}
          formKey="smoker"
          form={formData}
          setForm={setFormData}
          options={[
            { label: t('profile.step1.yes'), value: 'yes' },
            { label: t('profile.step1.no'), value: 'no' },
          ]}
        />
        {(error === 1 || error === 7) && <p className={styles.error}>{t('profile.error')}</p>}
      </div>
      <Button text={t('profile.step1.button')} onClick={handleNext} className={styles.buttonWidth} />
    </div>
  </div>
);

const Step2 = ({
  error,
  formData,
  handleChange,
  handleBack,
  handleNext
}: {
  error: number;
  formData: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBack: MouseEventHandler<SVGSVGElement>;
  handleNext: MouseEventHandler<HTMLButtonElement>;
}): JSX.Element => (
  <div className={`${styles.content}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <ArrowCarousel onClick={handleBack} fill="#191717" />
      </div>
      <div className={styles.progressContainer}>
        <p className={styles.progressText}>{t('profile.step2.progressText')}</p>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBar} style={{ width: '50%' }}></div>
        </div>
      </div>
    </div>
    <h1 className={styles.title}>{t('profile.step2.title')}</h1>
    <div className={styles.textContainer}>
      <div className={styles.formContainer}>
        <Input
          required={true}
          id='height'
          type='number'
          label={t('profile.step2.height')}
          name="height"
          value={formData.height}
          onChange={handleChange}
          min={120}
          max={220}
        />
        <Input
          required={true}
          id='weight'
          type='number'
          label={t('profile.step2.weight')}
          name="weight"
          value={formData.weight}
          onChange={handleChange}
          min={30}
          max={300}
        />
        {(error === 2 || error === 7) && <p className={styles.error}>{t('profile.error')}</p>}
      </div>
      <Button text={t('profile.step2.button')} onClick={handleNext} className={styles.buttonWidth} />
    </div>
  </div>
);

const Step3 = ({
  error,
  formData,
  handleBack,
  handleNext,
  setFormData,
}: {
  error: number;
  formData: any;
  handleBack: MouseEventHandler<SVGSVGElement>;
  handleNext: MouseEventHandler<HTMLButtonElement>;
  setFormData: any;
}): JSX.Element => (
  <div className={`${styles.content}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <ArrowCarousel onClick={handleBack} fill="#191717" />
      </div>
      <div className={styles.progressContainer}>
        <p className={styles.progressText}>{t('profile.step3.progressText')}</p>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBar} style={{ width: '75%' }}></div>
        </div>
      </div>
    </div>
    <div style={{ width: '100%' }}>
      <h1 className={styles.title}>{t('profile.step3.title')}</h1>
      <p className={styles.info}>{t('profile.step3.info')}</p>
    </div>
    <div className={styles.textContainer}>
      <div className={styles.formContainer}>
        <CustomRadioButton
          title={t('profile.step3.diabetic')}
          formKey="diabetic"
          form={formData}
          setForm={setFormData}
          options={[
            { label: t('profile.step3.type1'), value: 'type1' },
            { label: t('profile.step3.type2'), value: 'type2' },
            { label: t('profile.step3.no'), value: 'no' },
          ]}
        />
        <CustomRadioButton
          title={t('profile.step3.medication')}
          formKey="medication"
          form={formData}
          setForm={setFormData}
          options={[
            { label: t('profile.step3.yes'), value: 'yes' },
            { label: t('profile.step3.no'), value: 'no' },
          ]}
        />
        {(error === 3 || error === 7) && <p className={styles.error}>{t('profile.error')}</p>}
      </div>
      <Button text={t('profile.step3.button')} onClick={handleNext} className={styles.buttonWidth} />
    </div>
  </div>
);

const Step4 = ({
  error,
  formData,
  handleChange,
  handleBack,
  handleNext,
  handleTerms,
  viewTerms,
}: {
  error: number;
  formData: any;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBack: MouseEventHandler<SVGSVGElement>;
  handleNext: MouseEventHandler<HTMLSpanElement>;
  handleTerms: MouseEventHandler<HTMLDivElement>;
  viewTerms: MouseEventHandler<HTMLSpanElement>;
}) => (
  <div className={`${styles.content}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <ArrowCarousel onClick={handleBack} fill="#191717" />
      </div>
      <div className={styles.progressContainer}>
        <p className={styles.progressText}>{t('profile.step4.progressText')}</p>
        <div className={styles.progressBarContainer}>
          <div className={styles.progressBar} style={{ width: '100%' }}></div>
        </div>
      </div>
    </div>
    <div style={{ width: '100%' }}>
      <h1 className={styles.title}>{t('profile.step4.title')}</h1>
      <p className={styles.info}>{t('profile.step4.info')}</p>
    </div>
    <div className={styles.textContainer}>
      <div className={styles.formContainer}>
        <Input
          required={true}
          id='firstname'
          type='firstname'
          label={t('profile.step4.firstname')}
          name="firstname"
          value={formData.firstname}
          onChange={handleChange}
        />
        <Input
          required={true}
          id='email'
          type='email'
          label={t('profile.step4.email')}
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <div className={styles.checkboxItem}>
          <label className={styles.label}>{t('profile.step4.cookieLabel')}</label>
          <div className={styles.checkboxContainer} onClick={handleTerms} style={formData.terms ? { backgroundColor: '#27A577' } : {}}>
            <input 
              type="checkbox" 
              id="terms" 
              name="terms" 
              className={styles.checkbox} 
              required 
              checked={formData.terms}
              onClick={handleTerms}
            />
            <label htmlFor="terms" className={styles.customCheckbox}>
              {formData.terms ? 
                <span className={styles.custom_icon_checkbox}>
                  <img src={CheckIcon} alt="Check icon" width="10px" />
                </span> : 
                <span className={styles.custom_icon_checkbox_unchecked}></span>
              }
            </label>
            <p className={styles.checkboxLabel} style={formData.terms ? { color: '#FBFBF9' } : {}}>
              <span><Trans i18nKey="profile.step4.cookieText" components={{ strong: <strong /> }} /></span>
              &nbsp;
              <span 
                style={{ cursor: 'pointer', textDecoration: 'underline' }} 
                onClick={(e) => {
                  e.stopPropagation();
                  viewTerms(e);
                }}
              >
                <Trans i18nKey="profile.step4.cookieLink" components={{ strong: <strong /> }}/>
              </span>
            </p>
          </div>
        </div>
        {(error === 4 || error === 7) && <p className={styles.error}>{t('profile.error')}</p>}
      </div>
      <Button text={t('profile.step4.button')} onClick={handleNext} className={styles.buttonWidth} />
    </div>
  </div>
);

const Step5 = ({
  loading,
  handleBack,
  onSubmit,
  moreInfo,
}: {
  loading: boolean;
  handleBack: MouseEventHandler<SVGSVGElement>;
  onSubmit: MouseEventHandler<HTMLButtonElement>;
  moreInfo: MouseEventHandler<HTMLButtonElement>;
}) => (
  <div className={`${styles.content}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <ArrowCarousel onClick={handleBack} fill="#191717" />
      </div>
    </div>
    <div style={{ width: '100%' }}>
      <h1 className={styles.title}>{t('profile.step5.title')}</h1>
      <p className={styles.info}>{t('profile.step5.info')}</p>
    </div>
    <img src={ScannerFace} alt="Scanner Face" className={styles.scannerFace} />
    <div className={styles.textContainer} style={{ marginTop: '48px' }}>
      <div className={styles.formContainer}>
        <ul className={styles.itemsContainer}>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item0" /></li>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item1" /></li>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item2" /></li>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item3" /></li>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item4" /></li>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item5" /></li>
          <li className={styles.item}><Trans i18nKey="profile.step5.list.item6" /></li>
        </ul>
      </div>
      <div className={styles.buttons}>
        <Button text={loading ? t('profile.step5.button') + '...' : t('profile.step5.button')} onClick={onSubmit} className={styles.buttonWidth} />
        <Button text={loading ? t('profile.step5.buttonInfo') : t('profile.step5.buttonInfo')} className={styles.buttonWidth} style={{ color: '#191717', backgroundColor: 'transparent' }} onClick={moreInfo} />
      </div>
    </div>
  </div>
);

const Step6 = ({
  leaveTerms,
}: {
  leaveTerms: MouseEventHandler<SVGSVGElement>;
}) => (
  <div className={`${styles.content}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <ArrowCarousel onClick={leaveTerms} fill="#191717" />
      </div>
    </div>
    <div className={styles.textContainer} style={{ marginTop: '24px', gap: '8px' }}>
      <h1 className={styles.titleCookie}>{t('profile.step6.title')}</h1>

      {/* Introduction */}
      <p className={styles.descriptionCookie}>{t('profile.step6.introduction.p1')}</p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.introduction.p2" components={{ a: <a className={styles.linkCookie} /> }}/></p>

      {/* Identity */}
      <p className={styles.smallTitleCookie}><Trans i18nKey="profile.step6.identity.title"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.identity.p1"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.identity.p2"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.identity.p3"/></p>

      {/* Definitions */}
      <p className={styles.smallTitleCookie}><Trans i18nKey="profile.step6.definitions.title"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.definitions.p1"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.definitions.p2"/></p>

      {/* Purpose */}
      <p className={styles.smallTitleCookie}><Trans i18nKey="profile.step6.purpose.title"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.purpose.p1"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.purpose.p2"/></p>
      <ul className={styles.listCookie}>
        <li><p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.purpose.list.item1"/></p></li>
        <li><p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.purpose.list.item2"/></p></li>
        <li><p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.purpose.list.item3"/></p></li>
        <li><p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.purpose.list.item4"/></p></li>
        <li><p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.purpose.list.item5"/></p></li>
      </ul>

      {/* General */}
      <p className={styles.smallTitleCookie}><Trans i18nKey="profile.step6.general.title"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.general.p1"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.general.p2"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.general.p3"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.general.p4"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.general.p5"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.general.p6"/></p>

      {/* Website */}
      <p className={styles.smallTitleCookie}><Trans i18nKey="profile.step6.website.title"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.website.p1"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.website.p2"/></p>

      {/* Liability */}
      <p className={styles.smallTitleCookie}><Trans i18nKey="profile.step6.liability.title"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.liability.p1"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.liability.p2"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.liability.p3"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.liability.p4"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.liability.p5"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.liability.p6"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.liability.p7"/></p>

      {/* Access */}
      <p className={styles.smallTitleCookie}><Trans i18nKey="profile.step6.access.title"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.access.p1"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.access.p2"/></p>

      {/* Validity */}
      <p className={styles.smallTitleCookie}><Trans i18nKey="profile.step6.validity.title"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.validity.p1"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.validity.p2"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.validity.p3"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.validity.p4"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.validity.p5"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.validity.p6"/></p>

      {/* Questions */}
      <p className={styles.smallTitleCookie}>{t('profile.step6.questions.title')}</p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.questions.p1"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.questions.p2" components={{ a: <a className={styles.linkCookie} /> }}/></p>

      {/* Privacy */}
      <p className={styles.smallTitleCookie}><Trans i18nKey="profile.step6.privacy.title"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.privacy.p1"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.privacy.p2"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.privacy.p3"/></p>

      {/* Conflicts */}
      <p className={styles.smallTitleCookie}><Trans i18nKey="profile.step6.conflicts.title"/></p>
      <p className={styles.descriptionCookie}><Trans i18nKey="profile.step6.conflicts.p1"/></p>
    </div>
  </div>
);

const Step7 = ({
  handleBack,
}: {
  handleBack: MouseEventHandler<SVGSVGElement>;
}) => (
  <div className={`${styles.content}`}>
    <div className={styles.headerContainer}>
      <div className={styles.goBack}>
        <ArrowCarousel onClick={handleBack} fill="#191717" />
      </div>
    </div>
    <div style={{ width: '100%' }}>
      <h1 className={styles.titleInfo}>{t('profile.step7.title')}</h1>
      <p className={styles.textInfo}>{ <Trans i18nKey="profile.step7.p1" />}</p>
      <p className={styles.textInfo}>{ <Trans i18nKey="profile.step7.p2" />}</p>
      <p className={styles.textInfo}>{ <Trans i18nKey="profile.step7.p3" />}</p>
    </div>
    <img src={ScannerFace} alt="Scanner Face" className={styles.scannerFaceInfo} />
  </div>
);

export const Profile: FC = () => {
  const { i18n } = useTranslation();
  const { control } = useForm();
  const [step, setStep] = useState(1);
  const [error, setError] = useState(0);

  const viewTerms = () => {
    setStep(6);
  };

  const leaveTerms = () => {
    setStep(4);
  };

  const handleNext = () => {
    if (error === 7) return;

    setStep(prev => {
      switch (prev) {
        case 1:
          if (formData.age === '' || formData.gender === '' || formData.smoker === '' || parseInt(formData.age) < 13 || parseInt(formData.age) > 120) {
            setError(prev);
            return prev;
          }

          break;

        case 2:
          if (formData.height === '' || formData.weight === '' || parseInt(formData.height) < 120 || parseInt(formData.height) > 220 || parseInt(formData.weight) < 30 || parseInt(formData.weight) > 300) {
            setError(prev);
            return prev;
          }

          break;

        case 3:
          if (formData.diabetic === '' || formData.medication === '') {
            setError(prev);
            return prev;
          }

          break;

        case 4:
          if (formData.email === '' || formData.firstname === '' || !formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) || !formData.terms) {
            setError(prev);
            return prev;
          }

          break;
      }

      setError(0);
      return prev + 1;
    });
  }

  const handleBack = () => {
    setStep(prev => prev - 1);
  }

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    age: "",
    diabetic: "",
    height: "",
    medication: "",
    gender: "",
    smoker: "",
    weight: "",
    email: "",
    firstname: "",
    terms: false,
  });

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => {
      let isError = 0;

      if (name === 'age' && value > 120) isError = 7;
      if (name === 'age' && value < 13) isError = 7;

      if (name === 'height' && value > 220) isError = 7;
      if (name === 'height' && value < 120) isError = 7;

      if (name === 'weight' && value > 300) isError = 7;
      if (name === 'weight' && value < 30) isError = 7;

      if (name === 'firstname' && !value.match(/^[a-zA-Z\s-]*$/)) return { ...prevFormData };

      setError(isError);
      return { ...prevFormData, [name]: value };
    });
  };

  const handleTerms = () => {
    setFormData((prevFormData) => {
      if (!prevFormData.terms) {
        setError(0);
      }

      return { ...prevFormData, terms: !prevFormData.terms };
    });
  };

  const handleMoreInfo = () => {
    setStep(7);
  }
  const handleBackSubmit = () => {
    setStep(5);
  }

  const onSubmit = async (event: any) => {
    event.preventDefault();

    if (!formData.terms) {
      setStep(4);
      setError(4);
      return;
    }
  
    setLoading(true);
    const { age, diabetic, height, medication, gender, smoker, weight, email, firstname } = formData;
    const sessionId = uuidv4(); // Unique identifier for the session, passed back as part of the result object to identify the measurement

    const yobooUser: User = {
      email: email,
      height: parseInt(height),
      weight: parseInt(weight),
      locale: i18n.language,
      pharmacy: localStorage.getItem('pharmacy') || 'none',
      pharmacyReferral: localStorage.getItem('pharmacyId') || '',
      pharmacyIcon: localStorage.getItem('pharmacyIcon') || '',
      pharmacyName: localStorage.getItem('pharmacy') || 'none',
      gender: gender,
      firstname: firstname,
      terms: formData.terms,
      event: localStorage.getItem('utm_campaign') || '',
    };

    registerUser(yobooUser, sessionId);

    // Get the token ID
    const { data: configResponseData } = await axios.get('/api/configId');

    // Get a token from the back end
    const { data: tokenResponseData } = await axios.post('/api/token');
    const { Token, RefreshToken } = tokenResponseData;

    const payload = {
      identifier: uuidv4(), // Unique identifier for the user, it will be used to tag the measurement
      age: parseInt(age),
      height: parseInt(height),
      weight: parseInt(weight),
      gender,
      smoking: smoker === 'yes' ? '1' : '0',
      bloodpressuremedication: medication === 'yes' ? '1' : '0',
      diabetes: diabetic,
    };

    const buffer = Buffer.from(JSON.stringify(payload));
    const encryptedProfile = crypto.publicEncrypt(publicKey, buffer);

    const sessionIdValue = !!sessionId ? sessionId : 'undefined';

    setLoading(false);
    //original url: awe.na-east.nuralogix.ai
    window.location.href = `https://scan.yoboo-health.be/c/${
      configResponseData.configId
    }/${encodeURIComponent(encryptedProfile.toString('base64'))}/${encodeURIComponent(
      Token,
    )}/${encodeURIComponent(RefreshToken)}/${sessionIdValue}`;
  };

  const registerUser = (user: User, sessionId: string) => {
    axios.post('/api/register', { user, sessionId });
  };

  return (
    <Paper
        style={{
          boxShadow: 'none',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'transparent',
          padding: 0,
        }}
    >
      <Navbar isResults={false} />
      <div className={styles.container}>
        {step === 1 && <Step1 error={error} formData={formData} control={control} handleChange={handleChange} handleNext={handleNext} setFormData={setFormData} />}
        {step === 2 && <Step2 error={error} formData={formData} handleChange={handleChange} handleBack={handleBack} handleNext={handleNext} />}
        {step === 3 && <Step3 error={error} formData={formData} handleNext={handleNext} handleBack={handleBack} setFormData={setFormData} />}
        {step === 4 && <Step4 error={error} formData={formData} handleChange={handleChange} handleBack={handleBack} handleNext={handleNext} handleTerms={handleTerms} viewTerms={viewTerms} />}
        {step === 5 && <Step5 loading={loading} handleBack={handleBack} onSubmit={onSubmit} moreInfo={handleMoreInfo} />}
        {step === 6 && <Step6 leaveTerms={leaveTerms} />}
        {step === 7 && <Step7 handleBack={handleBackSubmit} />}
      </div>
      <Footer />
    </Paper>
  );
};
