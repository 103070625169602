export const Logo = ({ width = "100%", fill = "#27A577" }: { width: string,  fill?: string}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height="100%" viewBox="0 0 98 27" fill="none">
            <path d="M16.2234 0.3125V0.345474C16.2234 0.873065 16.108 1.40066 15.8772 1.87879C15.5145 2.65368 14.7725 4.08807 13.2227 6.759C12.0192 8.85288 11.1618 10.5181 10.6507 11.8206C10.1396 10.5181 9.2823 8.85288 8.07873 6.759C6.54542 4.08807 5.78701 2.65368 5.42429 1.87879C5.19347 1.40066 5.07806 0.873065 5.07806 0.345474V0.3125H0C0 1.53255 0.29677 2.3734 2.44011 5.48949C4.59993 8.62206 6.01783 10.7819 6.67732 11.903C8.14468 14.3926 8.57335 16.2886 8.57335 17.5087C8.57335 20.0642 8.49091 22.7186 8.30956 25.4225L8.26009 26.1809H12.9919L12.9425 25.4225C12.7611 22.7186 12.6787 20.0642 12.6787 17.5087C12.6787 14.9531 13.1403 14.3926 14.6077 11.903C15.2507 10.7819 16.6851 8.63854 18.8449 5.48949C20.9882 2.3734 21.285 1.53255 21.285 0.3125H16.2069H16.2234Z" fill={fill}/>
            <path d="M67.8617 26.2973C62.5033 26.2973 58.4805 22.3073 58.4805 17.015C58.4805 11.7226 62.6023 7.76562 67.8617 7.76562C73.1211 7.76562 77.3089 11.838 77.3089 17.015C77.3089 22.1919 73.1541 26.2973 67.8617 26.2973ZM67.8617 10.3706C65.3062 10.3706 63.4431 13.1569 63.4431 16.982C63.4431 20.807 65.3886 23.6758 67.8617 23.6758C70.3348 23.6758 72.3132 20.7905 72.3132 16.982C72.3132 13.1734 70.4502 10.3706 67.8617 10.3706Z" fill={fill}/>
            <path d="M88.5531 26.2973C83.1947 26.2973 79.1719 22.3073 79.1719 17.015C79.1719 11.7226 83.2937 7.76562 88.5531 7.76562C93.8125 7.76562 98.0003 11.838 98.0003 17.015C98.0003 22.1919 93.8455 26.2973 88.5531 26.2973ZM88.5531 10.3706C85.9976 10.3706 84.1345 13.1569 84.1345 16.982C84.1345 20.807 86.08 23.6758 88.5531 23.6758C91.0262 23.6758 93.0046 20.7905 93.0046 16.982C93.0046 13.1734 91.1416 10.3706 88.5531 10.3706Z" fill={fill}/>
            <path d="M26.5609 26.2973C21.2026 26.2973 17.1797 22.3073 17.1797 17.015C17.1797 11.7226 21.3015 7.76562 26.5609 7.76562C31.8203 7.76562 36.0081 11.838 36.0081 17.015C36.0081 22.1919 31.8533 26.2973 26.5609 26.2973ZM26.5609 10.3706C24.0054 10.3706 22.1423 13.1569 22.1423 16.982C22.1423 20.807 24.0878 23.6758 26.5609 23.6758C29.034 23.6758 31.0125 20.7905 31.0125 16.982C31.0125 13.1734 29.1494 10.3706 26.5609 10.3706Z" fill={fill}/>
            <path d="M56.4689 15.2012L56.403 14.7725C55.8424 12.2335 54.5069 10.354 52.3306 9.03499C51.0611 8.27658 49.7586 7.93035 48.4891 8.01279C47.302 8.07873 46.2798 8.49092 45.439 9.21635C44.9114 9.67799 44.5652 10.1891 44.3014 10.964C44.3014 10.997 44.3014 11.0135 44.2849 11.0464V11.0794C44.2849 11.1289 44.2684 11.1618 44.2519 11.1948C44.2189 11.3597 44.153 11.6565 44.1859 11.9203C44.1859 11.9203 45.3071 10.7826 46.4942 10.5683C46.7415 10.5188 47.0053 10.4859 47.2855 10.4694C49.8575 10.4694 51.7371 13.2557 51.7371 17.0808C51.7371 20.9058 49.8246 23.7746 47.2855 23.7746C44.7465 23.7746 42.768 20.8398 42.768 17.0808C42.768 10.3869 44.9279 3.54475 41.8942 0.939771C40.9709 0.148385 39.7179 0.0164872 39.2068 0H38.3L38.4319 0.70895C38.7946 2.40713 38.9595 5.45727 38.7781 8.60633C38.7287 9.23284 38.6627 9.89233 38.6133 10.5683C38.5308 11.9532 38.4484 13.5195 38.267 15.0528C38.1022 16.5037 38.1681 17.9546 38.4649 19.356C39.1409 22.4226 40.9545 24.5495 43.8562 25.6706C44.9279 26.0828 46.016 26.2806 47.1536 26.2806C48.2913 26.2806 48.2088 26.2312 48.7364 26.1487C51.7206 25.6706 53.9794 24.0878 55.4467 21.4499C56.5184 19.5044 56.8646 17.394 56.4854 15.1682V15.2012H56.4689Z" fill={fill}/>
        </svg>
    );
};
