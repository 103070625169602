import { FC } from 'react';
import styles from './styles.module.css';
import CheckIcon from '../../assets/images/check.png';

type CustomRadioButtonProps = any;

const CustomRadioButton: FC<CustomRadioButtonProps> = ({
    title,
    formKey,
    form,
    setForm,
    options,
}) => {
    const handleChange = (value: string) => {
        setForm((prevFormValue: any) => {
            return { ...prevFormValue, [formKey]: value };
        });
    };

    return (
        <div className={styles.container}>
            <span className={styles.title}>{title}</span>
            <div className={styles.content}>
                {options.map((option: any, index: number) => (
                    <label key={index} className={styles.custom_radio} style={form[formKey] === option.value ? { backgroundColor: '#27A577', color: '#FBFBF9' } : {}}>
                        <input
                            type="radio"
                            name="customRadio"
                            value={option.value}
                            checked={form[formKey] === option.value}
                            onChange={() => handleChange(option.value)}
                        />
                        {form[formKey] === option.value ? 
                            <span className={styles.custom_icon}>
                                <img src={CheckIcon} alt="Check icon" width="10px" />
                            </span>
                            :
                            <span className={styles.custom_icon_unchecked}></span>
                        }
                        {option.label}
                    </label>
                ))}
            </div>
        </div>
    );
};

export default CustomRadioButton;
