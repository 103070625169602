import { useState } from "react";
import { Link } from "react-router-dom";
import { ArrowCarousel } from "../../assets/svg";
import { t } from "i18next";
import styles from './styles.module.css'
import Navbar from "../../components/Navbar";
import { Button } from "../../components/Button";
import WelcomeFace from '../../assets/images/welcome-face.png';
import Footer from "../../components/Footer";
import { WavingHand } from "../../assets/svg/WavingHand";
import { HandsHiFive } from "../../assets/svg/HandsHiFive";
import { HandsHeart } from "../../assets/svg/HandsHeart";

const Step1 = ({ handleNext }: { handleNext: () => void }): JSX.Element => (
    <div className={styles.step1}>
        <Navbar isResults={false} />
        <div className={styles.headerContainer}>
            <div className={styles.goBack}>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <ArrowCarousel fill="#191717" />
                </Link>
            </div>
        </div>
        <div className={styles.allContent}>
            <div style={{ width: '100%', maxHeight: '925px' }}>
                <WavingHand />
            </div>
            <div className={styles.container}>
                <p className={styles.title1} style={{ margin: 0 }}>{t('intro.step1.title')}</p>
                <p className={styles.text1}>{t('intro.step1.text')}</p>
                <Button text={t('intro.step1.button')} buttonType="big" onClick={handleNext} />
            </div>
        </div>
    </div>
);

const Step2 = ({ handleNext, handleBack }: { handleNext: () => void, handleBack: () => void }): JSX.Element => (
    <div className={styles.step2}>
        <Navbar isResults={false} />
        <div className={styles.headerContainer}>
            <div className={styles.goBack}>
                <ArrowCarousel fill="#191717" onClick={handleBack} />
            </div>
        </div>
        <div className={styles.allContent}>
            <div className={styles.imageContainer}>
                <img className={styles.welcomeFace} src={WelcomeFace} alt="Smile" />
            </div>
            <div className={styles.container}>
                <p className={styles.title2} style={{ margin: 0 }}>{t('intro.step2.title')}</p>
                <p className={styles.text2}>{t('intro.step2.text')}</p>
                <Button text={t('intro.step2.button')} buttonType="big" onClick={handleNext} />
            </div>
        </div>
    </div>
);

const Step3 = ({ handleNext, handleBack }: { handleNext: () => void, handleBack: () => void }): JSX.Element => (
    <div className={styles.step3}>
        <Navbar isResults={false} />
        <div className={styles.headerContainer}>
            <div className={styles.goBack}>
                <ArrowCarousel fill="#191717" onClick={handleBack} />
            </div>
        </div>
        <div className={styles.allContent}>
            <div style={{ width: '100%' }}>
                <HandsHiFive />
            </div>
            <div className={styles.container}>
                <p className={styles.title3} style={{ margin: 0 }}>{t('intro.step3.title')}</p>
                <p className={styles.text3}>{t('intro.step3.text')}</p>
                <Button text={t('intro.step3.button')} buttonType="big" onClick={handleNext} />
            </div>
        </div>
    </div>
);

const Step4 = ({ handleNext, handleBack }: { handleNext: () => void, handleBack: () => void }): JSX.Element => (
    <div className={styles.step4}>
        <Navbar isResults={false} />
        <div className={styles.headerContainer}>
            <div className={styles.goBack}>
                <ArrowCarousel fill="#191717" onClick={handleBack} />
            </div>
        </div>
        <div className={styles.allContent4}>
            <div style={{ width: '100%' }}>
                <HandsHeart />
            </div>
            <div className={styles.container}>
                <p className={styles.title4} style={{ margin: 0 }}>{t('intro.step4.title')}</p>
                <p className={styles.text4}>{t('intro.step4.text')}</p>
                <Link to="/profile" style={{ textDecoration: "none" }}>
                    <Button text={t('intro.step4.button')} buttonType="big" onClick={handleNext} />
                </Link>
            </div>
        </div>
    </div>
);

export const Intro: React.FC = () => {
    const [currentStep, setCurrentStep] = useState(1); // State to track the current step
    const[currentColor, setCurrentColor] = useState('#EFECFF');

    const handleBack = () => {
        if (currentStep > 1) { // Ensure we don't go below step 1
            setCurrentStep(prevStep => {
                const newStep = prevStep - 1;
                
                switch (newStep) {
                    case 1:
                        setCurrentColor('#EFECFF'); // Step 1 background color
                        break;
                    case 2:
                        setCurrentColor('#FFEB99'); // Step 2 background color
                        break;
                    case 3:
                        setCurrentColor('#C7E2FB'); // Step 3 background color
                        break;
                    case 4:
                        setCurrentColor('#BBEDD2'); // Step 4 background color
                        break;
                    default:
                        setCurrentColor('transparent');
                }
                return newStep; // Move to the previous step
            });
        } else {
            console.log("Already at the first step.");
        }
    };
    

    // Function to move to the next step
    const handleNext = () => {
        if (currentStep < 4) {
            setCurrentStep(prevStep =>{
                switch (prevStep+1) {
                    case 1:
                        setCurrentColor('#EFECFF'); // Step 1 background color
                        break;
                    case 2:
                        setCurrentColor('#FFEB99'); // Step 2 background color
                        break;
                    case 3:
                        setCurrentColor('#C7E2FB'); // Step 3 background color
                        break;
                    case 4:
                        setCurrentColor('#BBEDD2'); // Step 4 background color
                        break;
                    default:
                        setCurrentColor('transparent');
                }
                return prevStep + 1
            }); // Move to the next step
        } else {
            console.log("Final step reached.");
        }
    };

    return (
        <>
            {currentStep === 1 && <Step1 handleNext={handleNext} />} {/* Show Step 1 */}
            {currentStep === 2 && <Step2 handleNext={handleNext} handleBack={handleBack} />} {/* Show Step 2 */}
            {currentStep === 3 && <Step3 handleNext={handleNext} handleBack={handleBack} />} {/* Show Step 3 */}
            {currentStep === 4 && <Step4 handleNext={handleNext} handleBack={handleBack} />} {/* Show Step 4 */}
            <Footer backgroundColor={currentColor} />
        </>
    );
};
