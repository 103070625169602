export const CurvedUnderline = ({
    width,
    fill = "none",
    height = "30%", 
    strokeWidth = 14,
}: {
    width: number;
    fill?: string;
    height?: string | number;
    strokeWidth?: number;
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 543 28"
            fill={fill}
            style={{ position: "absolute", bottom: 0, left: '47%', transform: 'translateX(-50%)', zIndex: 2 }}
            preserveAspectRatio="none"
        >
            <path
                d="M0.999756 14.8536C90.5111 8.41271 323.91 0.597901 541.415 20.8656"
                stroke="url(#paint0_linear_2176_50128)"
                strokeWidth={strokeWidth}
            />
            <defs>
                <linearGradient id="paint0_linear_2176_50128" x1="1.05392" y1="9.98475" x2="541.469" y2="15.996" gradientUnits="userSpaceOnUse">
                    <stop offset="0.14" stopColor="#FFD83D" />
                    <stop offset="0.88" stopColor="#4FCF99" />
                </linearGradient>
            </defs>
        </svg>
    );
};
