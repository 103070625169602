import { FC, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import { Proceed, Profile, Results, Welcome } from '../Pages';
import { useTranslation } from 'react-i18next';
import './styles.general.css';
import { Intro } from '../Pages/Intro';

export const App: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { i18n } = useTranslation();
  const history = useHistory();
  const theme = createTheme({
    palette: {
      primary: {
        main: '#6dbf51',
      },
      secondary: {
        main: '#213163',
      },
    },
  });

  // useEffect(() => {
  //   if (history) {
  //     history.listen(() => {
  //       window.location.reload();
  //     });
  //   }
  // }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <div style={{ backgroundColor: '#FBFBF9' }}>
        <CssBaseline />
        <Container
          component='main'
          sx={{ mb: 0, mt: 0 }}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            margin: 'auto',
            display: 'flex',
            minHeight: '100vh',
            maxWidth: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Router>
            <Switch>
              <Route path='/results'>
                <Results />
              </Route>
              <Route path='/proceed'>
                <Proceed />
              </Route>
              <Route path='/profile'>
                <Profile />
              </Route>
              <Route path='/intro'>
                <Intro />
              </Route>
              <Route path='/'>
                <Welcome />
              </Route>
            </Switch>
          </Router>
          {/* <footer className='footer'>
            <div className='footer-container'>
              <p className='footer-text opacity-50'>{t('footer.cookiePreferences')}</p>
              <p className='footer-text opacity-50'>{t('footer.allRightsReserved')}</p>
            </div>
            <div>
              <p className='footer-text' style={{ fontFamily: 'Geist_Bold' }}>{t('footer.feelingGood')}</p>
            </div>
          </footer>
          <Footer backgroundColor='transparent' /> */}
        </Container>
      </div>
    </ThemeProvider>
  );
};
