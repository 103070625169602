export const HandsHeart = ({ width="100%", height="auto" }: { fill?: string, width?: string|number, height?: string }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 1024 925" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1955_34635)">
                <g clip-path="url(#clip1_1955_34635)">
                    <path d="M448.299 614.069C395.716 581.518 386.777 548.867 386.376 528.61C385.7 493.88 400.324 473.222 429.52 473.523C463.323 473.848 485.859 498.888 502.134 483.538C532.107 455.244 484.356 410.498 433.927 398.604L438.258 396.701C459.517 352.406 476.369 299.773 438.258 285.626C425.739 280.969 413.119 290.283 401.676 304.456H401.15L401.225 304.381C401.225 304.381 404.405 279.742 385.901 274.258C366.971 268.649 354.551 283.598 344.385 303.705C348.517 292.437 343.434 271.604 324.754 271.404C288.622 271.003 293.455 336.006 246.756 403.437C202.511 467.338 119.455 473.172 -34.9386 413.778C-100.066 388.714 -185.201 323.611 -217.752 238.477L-225.364 573.305C-114.039 665.601 136.382 702.058 317.142 662.546C366.32 651.804 398.245 681.651 438.308 701.683C478.372 721.714 506.992 718.184 508.419 694.171C509.846 670.158 500.907 646.596 448.324 614.044L448.299 614.069Z" fill="#FFE5CC"/>
                    <path d="M-217.753 238.477C-185.202 323.611 -100.068 388.714 -34.9649 413.753C119.429 473.147 202.485 467.313 246.73 403.412C293.429 335.98 288.596 270.978 324.728 271.379C343.407 271.579 348.49 292.412 344.359 303.679" stroke="#191717" stroke-width="7.51186" stroke-miterlimit="10"/>
                    <path d="M401.199 304.355C401.199 304.355 404.379 279.716 385.874 274.232C353.573 264.642 340.177 314.947 324.728 348.6C309.278 382.253 278.004 426.097 261.753 435.988" stroke="#191717" stroke-width="7.51186" stroke-miterlimit="10"/>
                    <path d="M272.421 469.792C299.514 452.364 349.142 393.071 364.792 365.502C379.214 340.112 409.012 274.734 438.283 285.601C476.393 299.748 459.541 352.382 438.283 396.676" stroke="#191717" stroke-width="7.51186" stroke-miterlimit="10"/>
                    <path d="M306.825 503.545C306.825 465.986 355.652 388.689 420.755 396.201C475.466 402.511 534.71 452.765 502.133 483.514C485.858 498.863 463.322 473.823 429.519 473.498C400.323 473.197 385.7 493.855 386.376 528.585C386.776 548.842 395.715 581.493 448.298 614.045C500.881 646.596 509.82 670.158 508.393 694.171C506.966 718.184 478.346 721.715 438.283 701.683C398.219 681.651 366.294 651.779 317.116 662.546C136.356 702.059 -114.064 665.601 -225.39 573.305" stroke="#191717" stroke-width="7.51186" stroke-miterlimit="10"/>
                </g>
                <g clip-path="url(#clip2_1955_34635)">
                    <path d="M577.052 614.069C629.635 581.518 638.574 548.867 638.975 528.61C639.651 493.88 625.028 473.222 595.832 473.523C562.029 473.848 539.493 498.888 523.217 483.538C493.245 455.244 540.995 410.498 591.425 398.604L587.093 396.701C565.835 352.406 548.983 299.773 587.093 285.626C599.613 280.969 612.233 290.283 623.676 304.456H624.202L624.127 304.381C624.127 304.381 620.947 279.742 639.451 274.258C658.381 268.649 670.8 283.598 680.966 303.705C676.835 292.437 681.918 271.604 700.597 271.404C736.729 271.003 731.897 336.006 778.596 403.437C822.84 467.338 905.896 473.172 1060.29 413.778C1125.42 388.714 1210.55 323.611 1243.1 238.477L1250.72 573.305C1139.39 665.601 888.97 702.058 708.209 662.546C659.032 651.804 627.106 681.651 587.043 701.683C546.98 721.714 518.36 718.184 516.932 694.171C515.505 670.158 524.444 646.596 577.027 614.044L577.052 614.069Z" fill="#FFE5CC"/>
                    <path d="M1243.1 238.477C1210.55 323.611 1125.42 388.714 1060.32 413.753C905.921 473.147 822.865 467.313 778.621 403.412C731.922 335.98 736.754 270.978 700.622 271.379C681.943 271.579 676.86 292.412 680.991 303.679" stroke="#191717" stroke-width="7.51186" stroke-miterlimit="10"/>
                    <path d="M624.152 304.355C624.152 304.355 620.972 279.716 639.476 274.232C671.777 264.642 685.173 314.947 700.622 348.6C716.072 382.253 747.346 426.097 763.597 435.988" stroke="#191717" stroke-width="7.51186" stroke-miterlimit="10"/>
                    <path d="M752.93 469.792C725.837 452.364 676.209 393.071 660.559 365.502C646.136 340.112 616.339 274.734 587.068 285.601C548.958 299.748 565.81 352.382 587.068 396.676" stroke="#191717" stroke-width="7.51186" stroke-miterlimit="10"/>
                    <path d="M718.526 503.545C718.526 465.986 669.699 388.689 604.596 396.201C549.885 402.511 490.641 452.765 523.218 483.514C539.493 498.863 562.029 473.823 595.832 473.498C625.028 473.197 639.651 493.855 638.975 528.585C638.575 548.842 629.635 581.493 577.052 614.045C524.469 646.596 515.53 670.158 516.958 694.171C518.385 718.184 547.005 721.715 587.068 701.683C627.132 681.651 659.057 651.779 708.235 662.546C888.995 702.059 1139.42 665.601 1250.74 573.305" stroke="#191717" stroke-width="7.51186" stroke-miterlimit="10"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1955_34635">
                    <rect width="1024" height="924" fill="white" transform="translate(0 0.666992)"/>
                </clipPath>
                <clipPath id="clip1_1955_34635">
                    <rect width="744.35" height="499.213" fill="white" transform="translate(-229.17 219.271)"/>
                </clipPath>
                <clipPath id="clip2_1955_34635">
                    <rect width="744.35" height="499.213" fill="white" transform="matrix(-1 0 0 1 1254.52 219.271)"/>
                </clipPath>
            </defs>
        </svg>
    );
};
