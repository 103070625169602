export const WavingHand = ({ fill = "#D41111", width = "923", height="925" }: { fill?: string, width?: string|number, height?: string  }) => {
    return (
        <svg width="100%" height="auto" viewBox="0 0 923 925" fill="none" xmlns="http://www.w3.org/2000/svg" style={{float: "right"}}>
            <g clip-path="url(#clip0_1955_28511)">
                <path d="M1023.28 264.383C1023.28 264.383 887.368 423.165 781.738 435.921C707.563 444.893 618.34 409.752 541.398 315.882C491.579 255.097 542.013 168.321 530.071 156.128C495.793 121.126 456.565 227.781 449.156 231.915C438.593 237.795 292.706 63.6896 264.865 107.525C248.82 132.771 354.265 243.718 354.265 243.718C313.111 208.782 213.492 118.904 199.625 161.025C190.676 188.084 275.977 268.898 316.851 300.313C316.851 300.313 215.688 224.091 205.166 241.541C186.003 273.334 277.917 341.018 301.909 371.152C271.773 343.592 244.982 343.752 239.392 351.6C230.285 364.382 261.374 395.752 316.995 459.185C384.328 535.973 480.055 648.939 541.024 700.226C840.916 952.69 1187.08 583.81 1187.08 583.81" fill="#FFE5CC"/>
                <g clip-path="url(#clip1_1955_28511)">
                    <path d="M1023.28 264.381C1023.28 264.381 887.365 423.163 781.735 435.919C707.56 444.891 618.337 409.75 541.395 315.88C491.576 255.095 542.01 168.319 530.068 156.126C495.79 121.124 456.563 227.779 449.153 231.913C438.59 237.793 292.703 63.6877 264.862 107.523C248.817 132.769 354.262 243.716 354.262 243.716C313.108 208.78 213.489 118.902 199.622 161.023C190.673 188.082 275.974 268.896 316.848 300.312C316.848 300.312 215.685 224.089 205.163 241.539C186 273.332 277.914 341.016 301.906 371.15C271.77 343.59 244.979 343.75 239.389 351.598C230.282 364.38 261.371 395.75 316.993 459.183C384.325 535.971 480.052 648.937 541.021 700.224C840.913 952.688 1187.08 583.808 1187.08 583.808" stroke="#191717" stroke-width="8.38304" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M607.776 120.716C624.467 136.653 626.562 159.852 612.02 182.028" stroke="#191717" stroke-width="8.38304" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M640.349 136.019C649.781 149.136 648.394 158.568 642.876 172.749" stroke="#191717" stroke-width="8.38304" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M124.04 399.301C124.04 399.301 135.78 418.381 160.909 431.862" stroke="#191717" stroke-width="8.38304" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M100.268 419.974C100.268 419.974 100.391 428.296 111.104 440.888" stroke="#191717" stroke-width="8.38304" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1955_28511">
                    <rect width="1045.73" height="1045.73" fill="white" transform="translate(0 21.2041) rotate(-11.298)"/>
                </clipPath>
                <clipPath id="clip1_1955_28511">
                    <rect width="1045.09" height="790.8" fill="white" transform="translate(38.8721 142.134) rotate(-11.298)"/>
                </clipPath>
            </defs>
        </svg>
    );
};
