import { FC } from 'react';
import styles from './styles.module.css';
import Navbar from '../../components/Navbar';
import { t } from 'i18next';
import { ArrowCarousel } from '../../assets/svg';
import { useTranslation } from 'react-i18next';
import Footer from '../../components/Footer';
import DashboardImage from '../../assets/images/dashboard.png';
import Phones from '../../assets/images/phones.png'
import Disp from '../../assets/images/disp.png'
import { NotificationHand } from '../../assets/svg/NotificationHand';
import { useHistory } from 'react-router-dom';

export const Proceed: FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { i18n } = useTranslation();
    const history = useHistory();

    const handleBack = () => {
        history.goBack();
    };

    return (
        <>
            <div style={{ overflow: 'hidden' }}>
                <Navbar isResults={false} />
                <div className={styles.sectionContainer}>
                <div className={styles.container}>
                    <div className={styles.goBack}>
                        <ArrowCarousel onClick={handleBack} fill="#191717" />
                    </div>
                </div>
                <p className={styles.headTitle}>{t('proceed.title')}</p>
                    <div className={styles.content} >
                        <div className={styles.contentColumn}>
                        <h1 className={styles.title}>{t('proceed.section1.title')}</h1>
                        <p className={styles.text}>{t('proceed.section1.text')}</p>
                        </div>
                        <img className={styles.image} src={DashboardImage} alt="Dashboard" />
                    </div>
                    <div className={`${styles.content} ${styles.reverse}`}>
                        <img className={styles.image} src={Phones} alt="Phones" />
                        <div className={styles.contentColumn}>
                            <h1 className={styles.title}>{t('proceed.section2.title')}</h1>
                            <p className={styles.text}>{t('proceed.section2.text')}</p>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.contentColumn}>
                            <h1 className={styles.title}>{t('proceed.section3.title')}</h1>
                            <p className={styles.text}>{t('proceed.section3.text')}</p>
                        </div>
                        <img className={styles.image} src={Disp} alt="Disp" />
                    </div>
                </div>
            </div>
            <div className={styles.endSection}>
                <div className={styles.textEndContainer}>
                    <h1 className={styles.endSectionTitle}>{t('proceed.endSection.title')}</h1>
                    <p className={styles.endSectionText}>{t('proceed.endSection.p1')}</p>
                    <p className={styles.endSectionText}>{t('proceed.endSection.p2')}</p>
                    <p className={styles.endSectionText}>{t('proceed.endSection.p3')}</p>
                </div>
                <div className={styles.notificationHand} >
                    <NotificationHand />
                </div>
            </div>
            <Footer />
        </>
    );
};
