import { MouseEventHandler } from "react";

export const Close = ({ width = '100%', fill = "#191717", onClick = () => {} }: { fill?: string, width?: string, onClick?: MouseEventHandler<SVGSVGElement> }) => {
    return (
        <svg width={width} height="auto" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
            <path d="M6.0625 15L5 13.9375L8.9375 10L5 6.0625L6.0625 5L10 8.9375L13.9375 5L15 6.0625L11.0625 10L15 13.9375L13.9375 15L10 11.0625L6.0625 15Z"
                fill={fill} />
        </svg>
    );
};
